class Trans {
    language: any = [
        { lable: 'use.it.now', zh: '立即使用', en: 'Booking' },
        { lable: 'view.other', zh: '查看其他', en: 'View Others' },
        { lable: 'state', zh: '状态', en: 'State' },
        { lable: 'sign.in', zh: '签到', en: 'Sign In' },
        { lable: 'leave.the.chair', zh: '结束会议', en: 'Finish' },
        { lable: 'busy', zh: '忙碌', en: 'Busy' },
        { lable: 'free', zh: '空闲', en: 'Free' },
        { lable: 'time', zh: '时间', en: 'Time' },
        { lable: 'delay', zh: '延迟', en: 'Delay' },
        { lable: 'not.selected', zh: '尚未选择', en: 'Not Selected' },
        { lable: 'owner', zh: '使用人', en: 'Host' },
        { lable: 'been.booked', zh: '已登记', en: 'Booked' },
        { lable: 'sign.in.yes', zh: '已签到', en: 'Signed In' },
        { lable: 'sign.in.no', zh: '请10分钟内到会议室门口扫码签到', en: 'Pls Scan QRCode at the door in 10 mins' },
        { lable: 'not.select', zh: '尚未选择', en: 'No Select' },
        { lable: 'no.data', zh: '无数据', en: 'No Data' },
    ]
    constructor() {

    }
    translate(title?: string) {
        let isEN = loginUser.language == 'en';
        let newData = title;
        if (title) {
            let x = this.language.find((a: any) => a.lable === title)
            if (x && isEN) {
                newData = x.en
            }
            if (x && !isEN) {
                newData = x.zh
            }
        }
        return newData
    }

    t(title?: string) {
        return this.translate(title);
    }

}
const languageMessage = new Trans();
export default languageMessage;
