import React from 'react';
import './styles/index.scss';
import MobileRoute from './mobile.router';
import DeskTopRoute from "./desktop.router";
import { LocaleProvider } from 'antd-mobile';
import enUS from 'antd/lib/locale-provider/en_US';
import zhCN from 'antd/lib/locale-provider/zh_CN';
// import ScanningHomePage from "./pages/home";
// import RoomSelect from './pages/roomSelect';
// import {
//   BrowserRouter as Router,
//   Route,
//   Redirect,
//   Switch,
//   Link
// } from "react-router-dom";

function AllRoute() {
  let localeLang = (loginUser.language === 'en' ? enUS : zhCN);
  return (
    <LocaleProvider locale={localeLang}>
      <div className="App-Content">
        <MobileRoute />
      </div>
    </LocaleProvider>
      // {/* <DeskTopRoute/> */ }
  );
}

export default AllRoute;
