import React, { useState } from 'react';
import { DownOutlined } from "@ant-design/icons";
import { Menu } from "antd-mobile";
import { DatePicker } from "antd-mobile";
import appState from "../appStatus";
import { observer, inject, Provider } from "mobx-react";
import { toJS } from 'mobx';
import { cloneDeep } from 'lodash';
import classnames from 'classnames';
import { message } from "antd";
import dayjs from 'dayjs';
import routerHelper from "../../utils/helper/router.helper";
import Translate from '../appStatus/language/language';

interface roomStatus {
  name: string;
  id: string;
  officeArea: string;
  capacity?: number;
  isBusy?: boolean;
  ownerName?:string;
  defaultImageThumbnaiUrl?: string; // 默认图片
  currentMeetingEndAt?: string;
  currentMeetingBeginAt?: string;
  jumpRoute: (path: string, isBusy?: boolean) => void;
  [key:string]:any;
}

function RoomItem(props: roomStatus) {
  console.log('wp-props',props)
  let { currentMeetingEndAt, currentMeetingBeginAt,officeArea,currentMeetingOwnerDeptName,currentMeetingOwnerName } = props;
  return (
    <div
      className={classnames("room-item", {
        isBusy: props.isBusy,
      })}
      onClick={() => {
        props.jumpRoute(props.id, props.isBusy);
      }}
    >
      <div className="room-item-des">
        <dt>{props.name} <small>{ officeArea}</small></dt>

        <dd className="room-item-des-dd">
          {!props.isBusy &&
                  <p className="room-item-des-dd-p">
                    <div className="room-item-des-dd-p-label">
                      <span className="iconfont icon-gongzuozhuangtai icon-gongzuozhuangtai-size" />
                      <span className="room-item-des-dd-p-status">{Translate.translate('state')}: </span>
                      <span className="room-item-des-dd-p-dst"> {Translate.translate('free')} </span>
                    </div>
                  </p>
          }
          {props.isBusy &&
                  <p className="room-item-des-dd-p">
                    <div className="room-item-des-dd-p-label">
                      <span className="iconfont icon-shijian icon-shijians-size" />
                      <span className="room-item-des-dd-p-status">{Translate.translate('time')}: </span>
                      <span className="room-item-des-dd-p-dst">
                        {dayjs(currentMeetingBeginAt).format("MM-DD HH:mm")}-{dayjs(currentMeetingEndAt).format("HH:mm")}
                      </span>
                    </div>
                    <div className="room-item-des-dd-p-label">
                      <span className="iconfont icon-user icon-gongzuozhuangtai-size" />
                      <span className="room-item-des-dd-p-status">{Translate.translate('owner')}: </span>
              <span className="room-item-des-dd-p-dst"> {currentMeetingOwnerName ? currentMeetingOwnerName : '未知'}
              {currentMeetingOwnerDeptName ? "【" + currentMeetingOwnerDeptName + "】" : ''}

              </span>
                    </div>
                  </p>
          }
          {/* <span className="room-item-tips">
            {props.capacity ? props.capacity : 10}人
          </span>
          {props.isBusy && (
            <span style={{ color: "red" }}>&nbsp;&nbsp;使用中&nbsp;</span>
          )}
          {props.isBusy && (
            <span style={{ color: "red" }}>
              {dayjs(currentMeetingBeginAt).format("MM-DD HH:mm")}-
              {dayjs(currentMeetingEndAt).format("HH:mm")}
            </span>
          )} */}
        </dd>
      </div>
      <div className="room-item-image">
        {props.defaultImageThumbnaiUrl ?
          <img src={props.defaultImageThumbnaiUrl } alt={props.name} />
          :
          <img src={require("../../assent/images/demo.png")} alt={props.name} />
        }
      </div>

    </div>
  );
}

interface NavMenuStatus {
  areas: any;
  onOk: (data: any) => void;
  defaultValue?: any;
  onCancel?: () => void;
}


function renderFilterTitle(areas: any, value: any) {
  const [areaId, children] = value || [];
  let area: any = {};
  let areaChildren = [];
  if (areaId && children) {
    area = areas.find((a: any) => a.id === areaId);
    if (area && area.children) {
      areaChildren = area.children.filter((a: any) => children.includes(a.id));
    }
    console.log(area, areaChildren);
  }
  if (Object.entries(area).length && toJS(areaChildren) && toJS(areaChildren).length) {
    return `【${area.name}】- ${toJS(areaChildren).map((a: any) => a.name)}`;
  }
  return '';
}



function NavMenu(param: NavMenuStatus) {
  const [selectData, setSelectData] = useState<any>(param.defaultValue);
  let title = renderFilterTitle(param.areas, selectData);
  return (
    <div className="nav-content">
      <div className="nav-content-title">{title ? title : Translate.t('not.select')}</div>
      <Menu
        data={param.areas || []}
        multiSelect
        onChange={(data) => {
          setSelectData(cloneDeep(data));
        }}
        value={selectData}
        onOk={(data) => {
          param.onOk(data);
        }}
        onCancel={() => {
          param.onCancel && param.onCancel();
        }}
        height={document.documentElement.clientHeight - 60}
      />
    </div>
  );
}

const busyState = [
  { value: "free", label: Translate.translate('free') },
  { value: "busy", label: Translate.translate('busy') },
];

@inject("store")
@observer
class RoomSelect extends React.Component<any> {
  constructor(props: any) {
    super(props);
    this.props.store.getAreas();
    this.props.store.getAllMeetingRoomsWidhtStatus();

    // this.props
  }
  handleAreas(data: any) {
    let areas = [
      {
        value: "all",
        label: "All",
        children: [...data],
      },
    ];
    return areas;
  }

  render() {
    const { store } = this.props;
    const {
      showFilter,
      allAreas,
      allRooms,
      selectAreas,
      busyStatus,
      localRoomId,
      translate
    } = store;
    return (
      <div className="hoom-room-select">
        <div className="header">
          {/* <DatePicker
            mode="date"
            title="请选择日期"
            className={"header-time-select-content"}
            value={new Date(nowDate)}
            minDate={new Date()}
          > */}
          <div className="header-time-select-s">
            <a href="javascript:;">
              &nbsp;
              {/* 时间选择 <span className="iconfont icon-time icon-time-size" /> */}
            </a>
          </div>
          {/* </DatePicker> */}
          <div className="header-time-space">
            <div className="header-time-btn">
              {busyState.map(item => {
                return (
                  <p
                    className={classnames({
                      active: busyStatus === item.value,
                    })}
                    onClick={() => {
                      store.setBusyStatus(item.value);
                      store.getAllMeetingRoomsWidhtStatus();
                    }}
                    key={item.value}
                  >
                    {item.label}
                  </p>
                );
              })}
            </div>
          </div>
          <div className="header-time-filter">
            <a
              href="javascript:;"
              onClick={() => {
                store.setShowFilter();
              }}
            >
              <span className="iconfont icon-caidan icon-caidan-size" />
            </a>
          </div>
        </div>
        <div className="hoom-room-select-content">
          {allRooms &&
            !!allRooms.length &&
            allRooms.map((item: any) => {
              let newItem = {
                ...item,
                isBusy: busyStatus === "busy",
                jumpRoute: (roomId: string, isBusy: boolean) => {
                  if (isBusy) {
                    // message.warn('该会议室正在使用中...');
                    this.props.history.push(routerHelper.getMobileUrl(`homePage/${roomId}`));
                  }
                  if (appState.localRoomId === roomId) {
                    this.props.history.push(routerHelper.getMobileUrl(`homePage/${roomId}?from=qrcode`));
                  } else {
                    this.props.history.push(routerHelper.getMobileUrl(`homePage/${roomId}`));
                  }
                },
              };
              return <RoomItem {...newItem} />;
            })}
          {!allRooms.length && <div className="no-room">{Translate.t('no.data')}</div>}
        </div>
        {showFilter && (
          <NavMenu
            {...{
              areas: allAreas,
              defaultValue: selectAreas,
              onOk: (data: any) => {
                store.setSelectAreas(data);
                store.getAllMeetingRoomsWidhtStatus().then((res: any) => {
                  store.setShowFilter();
                });
              },
              onCancel: () => {
                store.setShowFilter();
              },
            }}
          />
        )}
      </div>
    );
  }
}

export default (props: any) => (
  <Provider store={appState}>
    <RoomSelect {...props} />
  </Provider>
);
