import axois, { AxiosInstance, AxiosResponse, AxiosError } from 'axios';
import * as Cookies from 'js-cookie';
import { auth } from '../Auth';
import { Toast } from 'antd-mobile';
import { message } from "antd";
// import { helper } from 'Models/Base/Helper';
// import { ls } from 'app/locale/locale.state';
// import { appContainer } from '../app.container';
// import { Sentry } from '../cake/sentry';
// import { eventHelper } from 'app/helper/event.helper';

export interface IApiNotice {
    code: string;
    noticeMessage: string;
    message: string;
    warnMessage: string;
}

console.log("wp-apiServiceHost", apiServiceHost);
let host: string =
  typeof apiServiceHost !== "undefined" &&
  typeof baseUrl !== "undefined" ? `${apiServiceHost}`
    : "https://hyshi.bayrand.com/api/v2.0";
export const requestHelper: AxiosInstance = axois.create({
  baseURL: host,
  headers: {
    "x-hys-session": auth.sessionId,
    "x-hys-platform": auth.platform,
    "x-lang": loginUser && loginUser.lang,
    "x-authenticity-token": Cookies.get("authenticityToken"),
  },
});

requestHelper.interceptors.request.use((config) => {
    config.headers['x-hys-platform'] = auth.platform;
    config.headers['x-lang'] = loginUser && loginUser.lang;
    return config;
});

requestHelper.interceptors.response.use(
    (res: AxiosResponse) => {
        if (res.status === 401) {
            console.error('请求发生错误!!!')
            // eventHelper.dispatch(eventHelper.event.AuthFail);
        }
        if (res.data.status !== 0) {
            let mobile = false;
            if(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
                mobile = true;
            } else {
                mobile = false;
            }
            if (res.data.noticeMessage) {
                if (!mobile) {
                    message.info(res.data.noticeMessage);
                } else {
                    Toast.info(res.data.noticeMessage);
                }
            } else if (res.data.warnMessage) {
                if (!mobile) {
                    message.warn(res.data.warnMessage);
                } else {
                    Toast.fail(res.data.warnMessage);
                }
            }
        }
        if (res.data.code === 400 || res.data.code === 404 || res.data.code === 401) {
            return Promise.reject(res.data);
        }
        return res;
    },
    (err: AxiosError) => {
        let showReport: boolean = true;
        let res = err.response;
        if (res === undefined) {
            return Promise.reject({});
        }
        let message = res.data;
        return Promise.reject(res && res.data);
    }
);
