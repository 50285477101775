import { makeAutoObservable } from "mobx";
import appServer, { SearchRoomParmas } from "./server";
import "dayjs/locale/zh-cn";
import dayjs from "dayjs";
import { cloneDeep } from "lodash";
import routerHelper from "../../utils/helper/router.helper";
import language from "./language/language";

interface bookingStatus {
  subject: string;
  roomId: string;
  startTime: any;
  meetingDate: string;
  duration: number;
  location: string;
  checkinNow: boolean;
}

class AppStatus {
  showFilter: boolean = false;
  showCalendar: boolean = false;
  nowDate: any = dayjs();
  roomInfo: any;
  allAreas: any[] = [];
  allRooms: any[] = [];
  selectAreas: any[] = [];
  busyStatus: string = "free";
  localRoomId: string = "";
  bookingInfo: bookingStatus = {
    subject: `${loginUser.name}`,
    roomId: "",
    meetingDate: dayjs().format("YYYY-MM-DD"),
    startTime: dayjs().format("HH:mm"),
    duration: 30,
    location: "",
    checkinNow: false
  };

  constructor() {
    makeAutoObservable(this);
  }

  setShowFilter() {
    this.showFilter = !this.showFilter;
  }
  setLocalRoomId(roomId: string) {
    console.log('wopsdfasdf',roomId)
    this.localRoomId = roomId;
  }
  setSelectAreas(data: any) {
    console.log(data);
    this.selectAreas = data;
  }
  setBusyStatus(status: string) {
    this.busyStatus = status;
  }
  handleAreas(areas: Array<any>): any[] {
    let allAreas = areas.map((item) => {
      return {
        ...item,
        label: item.name,
        value: item.id,
        children: item.children ? this.handleAreas(item.children) : [],
      };
    });
    return allAreas;
    // return [
    //   {
    //     label: "全部区域",
    //     value: "all",
    //     children: [...allAreas],
    //   },
    // ];
  }
  getAreas() {
    return appServer.getAreas().then((res) => {
      let data = this.handleAreas(res);
      this.setAreas(data);
    });
  }


  finishMeeting(meetingId: string) {
    return appServer.finishMeeting(meetingId);
  }
  delayMeeting(meetingId: string,during:number) {
    return appServer.delayMeeting(meetingId, during);
  }

  setShowCalendar() {
    this.showCalendar = !this.showCalendar;
  }
  setNowDate(date: any) {
    this.nowDate = date;
  }

  getRoomInfo(roomId: string) {
    this.bookingInfo.roomId = roomId;
    return appServer.getRoomInfo(roomId).then((res) => {
      this.roomInfo = res;
    });
  }

  setBookingDur(time: number) {
    this.bookingInfo = {
      ...this.bookingInfo,
      duration: time,
    };
  }
  setAreas(areas: any) {
    this.allAreas = areas;
  }

  getAllMeetingRoomsWidhtStatus() {
    let newParams: any = {
      officeAreaIds: cloneDeep(this.selectAreas).pop() || ["all"],
      busyType: this.busyStatus,
    };

    // 默认全部
    if (newParams.officeAreaIds.length === 0) {
      newParams.officeAreaIds = ["all"];
    }

    return appServer.getAllMeetingRoomsWidhtStatus(newParams).then((res) => {
      let allRoom: any[] = [];
      if (res && res.length) {
        res.forEach(({ rooms }: any) => {
          if (rooms && rooms.length) {
            allRoom = [...allRoom, ...rooms];
          }
        });
      }
      this.allRooms = [...allRoom];
      return res;
    });
  }

  bookingMeeting() {
    if (window.location.href.includes("from=qrcode")) {
      this.bookingInfo = {
        ...this.bookingInfo,
        location: this.localRoomId,
      };
    } else {
      this.bookingInfo = {
        ...this.bookingInfo,
        location: 'others'     // 用于订其它会议室，加5分钟
      };
    }
    return appServer.formBookingMeeting(this.bookingInfo).then((res) => {
      // router.push(routerHelper.getMobileUrl(`homePage/${this.localRoomId}`));
      this.getRoomInfo(this.localRoomId);
      return res;
    });
  }
}

const appState = new AppStatus();
export default appState;
