import "./styles/index.scss";

import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";

import React from "react";
import RoomSelect from "./pages/roomSelect";
import ScanningHomePage from "./pages/home";
import routerHelper from './utils/helper/router.helper';

function MobileRoute() {
  return (
    <div className="App-Content">
      <Router>
        <Switch>
          <Route
            exact
            path="/home"
            // name="ScanningHomePage"
            render={() => (
              <Redirect
                to={routerHelper.getMobileUrl(`homePage/:roomId`)}
              ></Redirect>
            )}
          />
          <Route
            exact
            path={routerHelper.getMobileUrl(`homePage/:roomId`)}
            // name="ScanningHomePage"
            component={ScanningHomePage}
          />
          <Route
            exact
            path={routerHelper.getMobileUrl(`selectroom`)}
            // name="RoomSelect"
            component={RoomSelect}
          />
          <Redirect to={"/home"} />
        </Switch>
      </Router>
    </div>
  );
}

export default MobileRoute;
