import "dayjs/locale/zh-cn";

import { Provider, inject, observer } from "mobx-react";

import { Link } from "react-router-dom";
import React from "react";
import Translate from '../appStatus/language/language';
import appState from "../appStatus";
import classnames from 'classnames';
import dayjs from "dayjs";
import routerHelper from '../../utils/helper/router.helper';
import { toJS } from 'mobx';

interface ScanningHomePageStatus {
  showCalendar: boolean;
  nowDate: any;
  roomId?: string;
}

const timeItems = [
  { time: 30, label: '30 mins' }, { time: 60, label: '1 hours' }, { time: 90, label: '90 mins' }, { time: 120, label: '2 hours' },
  { time: 240, label: '4 hours' }
];


@inject('store')
@observer
class ScanningHomePage extends React.Component<any, ScanningHomePageStatus> {
  constructor(props: any) {
    super(props);
    const { match } = this.props;
    const { store } = this.props;
    appState.getRoomInfo(match.params.roomId);
    this.handleSumit = this.handleSumit.bind(this);
    this.hasMeetting = this.hasMeetting.bind(this);
    if (window.location.href.includes('from=qrcode')) {
      store.setLocalRoomId(match.params.roomId);
    }
  }
  handleSumit() {
    this.props.store.bookingMeeting().then((res: any) => {
      const { match } = this.props;
      appState.getRoomInfo(match.params.roomId);
    });
  }
  hasMeetting(meetings: any[]) {
    let hasMeeting = meetings.find((item) => item.abilities.includes("ongoing"));
    if (hasMeeting) {
      return hasMeeting;
    } else {
      return undefined;
    }
  }
  render() {
    const { store } = this.props;
    const { nowDate, roomInfo, bookingInfo } = store;
    const { language } = loginUser;

    let dateLang = 'zh-cn'; // 日期不认zh
    if (language === 'en') {
      dateLang = 'en';
    }

    let maxDuration = roomInfo?.meetingMaxDuration || 0;

    // const { translate } = Translate;
    const hasMeeting = this.hasMeetting(roomInfo && roomInfo.meetings || []);

    return (
      <div className="scan-home-page-content">
        <div className="scan-home-page-content-banner">
          <div
            className="btn zh-en-btn"
            onClick={(e) => {
              e.stopPropagation();
              let splitParam = '?';
              if (window.location.href.includes('?')) {
                splitParam = '&';
              }
              if (language === 'en') {
                window.location.href = `${window.location.href}${splitParam}lang=zh`
              } else {
                window.location.href = `${window.location.href}${splitParam}lang=en`
              }
            }}
          >
            <span className="iconfont icon-zhongyingwenyingwen icon-bangzhu-css " />
          </div>
          <div
            className="btn help-btn"
            onClick={(e) => {
              e.stopPropagation();
              window.open('http://d.cdn6.cn/a/jnj/smartfm-jnj.html')
            }}
          >
            <span className="iconfont icon-bangzhu icon-bangzhu-css " />
          </div>
          {
            hasMeeting &&
            <div className="has-meeting-bg">
              <span className="iconfont icon-shijian1 icon-shijian1-css " />
            </div>
          }
          {roomInfo && roomInfo.defaultImageUrl ?
            <img src={roomInfo.defaultImageUrl} alt="banner" />
            :
            <img src={require('../../assent/images/1.png')} alt="banner" />
          }
        </div>
        <div className="scan-home-page-content-header">
          {roomInfo && roomInfo.name}
          {/* {roomInfo && roomInfo.officeArea} */}
        </div>
        <div className="scan-home-page-content-date">
          <p className="content">
            <span className="iconfont icon-rili icon-rili-size" />
            {dayjs(nowDate).locale(dateLang).format("YYYY-MM-DD ddd")}
          </p>
        </div>

        <div className="scan-home-page-content-time">
          <span className="iconfont icon-shijian icon-shijian-size" />
          {dayjs().format("HH:mm")}
        </div>
        {hasMeeting &&
          <div className="scan-home-page-content-time">
            <span className="iconfont icon-user icon-shijian-size" />
            {hasMeeting.ownerName} {hasMeeting.ownerDeptName ? '【' + hasMeeting.ownerDeptName + '】' : ''}
          </div>}
        {hasMeeting &&
          <div className="scan-home-page-content-time">
            <span className="iconfont icon-gongzuozhuangtai icon-shijian-size" />
            <span className="label">{Translate.translate('state')}:</span>{Translate.translate('been.booked')} &nbsp;
          {dayjs(hasMeeting.beginTime).format('HH:mm')} - {dayjs(hasMeeting.endTime).format('HH:mm')}
          </div>}
        {hasMeeting &&
          <div className="scan-home-page-content-time">
            <span className="iconfont icon-zhuangtaixiugai icon-shijian-size" />
            <span className="label">{Translate.translate('sign.in')}:</span>
            {hasMeeting.checkedStatus === 'Y' ? Translate.translate('sign.in.yes') : Translate.translate('sign.in.no')}
          </div>}
        {/* <h1 className="scan-home-page-content-title">
          {hasMeeting
            ? `${hasMeeting.ownerName} [${hasMeeting.ownerDeptName}] ${
                hasMeeting.checkedStatus === "N"
                  ? "已登记,尚未签到"
                  : "正在使用..."
              }`
            : "您计划使用"}
        </h1> */}
        {/* {hasMeeting && (
          <p className="scan-home-page-content-meetingtime">
            {dayjs(hasMeeting.beginTime).format("YYYY-MM-DD HH:mm")}
            &nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;
            {dayjs(hasMeeting.endTime).format("HH:mm")}
            {hasMeeting.checkedStatus === "N" &&
              hasMeeting.ownerId === loginUser.id && (
                <p color="red">请在15分钟内到会议室门口扫码签到</p>
              )}
          </p>
        )} */}
        {!hasMeeting &&
          <div className="scan-home-page-content-btn delay" >
            {timeItems.map((item, index) => {
              if (item.time > maxDuration) {
                return null;
              }
              return (
                <p key={index}
                  className={classnames({
                    timeAction: bookingInfo.duration === item.time,
                  })}
                  onClick={(e) => {
                    e.stopPropagation();
                    store.setBookingDur(item.time);
                  }}
                >
                  {item.label}
                </p>
              );
            })
            }
          </div>
        }
        {hasMeeting &&
          hasMeeting.abilities &&
          hasMeeting.abilities.includes("delay") && (
            <div className="scan-home-page-content-btn delay">
              {timeItems.map((item, index) => {
                if (item.time > maxDuration) {
                  return null;
                }
                return (
                  <p key={index}
                    onClick={() => {
                      store.delayMeeting(hasMeeting.id, item.time).then((res: any) => {
                        if (res) {
                          const { match } = this.props;
                          appState.getRoomInfo(match.params.roomId);
                        }
                      });
                    }}
                  >
                    {Translate.translate('delay')} {item.label}
                  </p>
                );
              })
              }
            </div>
          )}
        <div className="scan-home-page-content-footer-btn">
          {!hasMeeting && (
            <p id="bookingNow"
              onClick={() => {
                this.handleSumit();
              }}
            >
              {Translate.translate('use.it.now')}
            </p>
          )}
          {hasMeeting &&
            hasMeeting.abilities &&
            hasMeeting.abilities.includes("close") && (
              <p
                onClick={() => {
                  store.finishMeeting(hasMeeting.id).then((res: any) => {
                    if (res) {
                      const { match } = this.props;
                      appState.getRoomInfo(match.params.roomId);
                    }
                  });
                }}
                className={'close-btn'}
              >
                {Translate.translate('leave.the.chair')}
              </p>
            )}
          {/* {hasMeeting &&
            hasMeeting.abilities &&
            hasMeeting.abilities.includes("delay") && (
              <p
                onClick={() => {
                  store.delayMeeting(hasMeeting.id).then((res: any) => {
                    if (res) {
                      const { match } = this.props;
                      appState.getRoomInfo(match.params.roomId);
                    }
                  });
                }}
              >
                延迟会议
              </p>
            )} */}
          <p>
            <Link to={routerHelper.getMobileUrl("selectroom")}>
              {Translate.translate('view.other')}
            </Link>
          </p>
        </div>
      </div >
    );
  }
}

export default (props: any) => {
  return (
    <Provider store={appState}>
      <ScanningHomePage {...props} />
    </Provider>
  );
};

