import dayjs from 'dayjs';
import { requestHelper } from "../../utils/helper/request.helper";

interface MeetingStatus {
  subject:string;
  roomId: string;
  time: any;
  during: number;
}

export interface SearchRoomParmas {
  officeAreaIds?: Array<any>;
  busyType:string
}
export class AppServer {
  getRoomInfo(roomId: string) {
    return requestHelper
      .get(`meeting-rooms/${roomId}?result=full`)
      .then((res) => {
        let room = res.data.data;
        if (room) {
          if (room.deviceStatus) {
            for (let key in room.deviceStatus) {
              if (room.deviceStatus[key] !== undefined) {
                room[key] = room.deviceStatus[key];
              }
            }
          }
          room.meetings = room.meetings ? room.meetings : [];
        }
        return room;
      });
  }
  getAreas() {
    return requestHelper
      .get("office-areas", {
        params: {
          roomType: "ROOM",
        },
      })
      .then((res) => {
        return res.data.data;
      });
  }
  getAllMeetingRoomsWidhtStatus(params: SearchRoomParmas) {
    let officeAreaUrl: string = "";
    params.officeAreaIds &&
      params.officeAreaIds.map((officeArea) => {
        officeAreaUrl += `&officeAreaIds=${officeArea}`;
      });
    officeAreaUrl += `&start=${dayjs().format("YYYY-MM-DD HH:mm")}`;
    officeAreaUrl += `&end=${dayjs().add(30, "m").format("YYYY-MM-DD HH:mm")}`;
    officeAreaUrl += `&busyType=${params.busyType}`;
    return requestHelper
      .get(`choose-video-conferencing-rooms?${officeAreaUrl}`)
      .then<any>((res) => {
        return res.data.data;
      });
  }
  finishMeeting(id: string) {
    return requestHelper.put(`/meetings/${id}/finish`).then((res) => {
      return res;
    });
  }
  delayMeeting(id: string, during: number) {
    return requestHelper.put(`/meetings/${id}/delay`, {
        delayMinutes: during,
      })
      .then((res) => {
        return res;
      });
  }

  formBookingMeeting(meeting: any) {
    if (meeting.id) {
      return requestHelper
        .put(`meetings/${meeting.id}`, meeting)
        .then((res) => {
          if (res.data.data) {
            return res.data.data;
          } else {
            return res.data;
          }
        });
    } else {
      return requestHelper.post(`meetings`, meeting).then((res) => {
        if (res.data.data) {
          return res.data.data;
        } else {
          return res.data;
        }
      });
    }
  }
}
const appServer = new AppServer();
export default appServer;
